import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";

import "./SideDrawer.scss";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div className='header'>
          <h4>Adam Woolf</h4>
          <small>Web Developer | Musician</small>

          <hr></hr>
        </div>
        <div className='side-drawer__menu'>
          <Link onClick={props.click} to='/'>
            Home
          </Link>

          <Link onClick={props.click} to='/webdev'>
            Web Developer
          </Link>

          <Link onClick={props.click} to='/musician'>
            Musician
          </Link>

          <Link onClick={props.click} to='/store'>
            Shop
          </Link>

          {/* <Link onClick={props.click} to='/for_sale'>
            Used Scores and more...
          </Link> */}
          {/* 
          <Link onClick={props.click} to='/discography'>
            Recordings
          </Link> */}
          {/* <li>
            <Link onClick={props.click} to="/photos">
              Photography
            </Link>
          </li> */}

          {/* <li>
            <Link onClick={props.click} to="/posts">
              Blog
            </Link>
          </li> */}

          <Link onClick={props.click} to='/contact'>
            Contact
          </Link>
        </div>

        <div className='social'>
          <a
            target='_blank'
            href='https://www.linkedin.com/in/adam-woolf-5b825344/'
          >
            <FaLinkedin size={30} />
          </a>

          {/* <a href='https://twitter.com/webspinner15'>
            <FaTwitter size={30} />
          </a> */}
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;
