import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./DownloadStyles.scss";
import { getStoreItems } from "../../contentful";

const Download = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getStoreItems().then((data) => setProducts(data));
  }, []);

  const location = useLocation();
  const now = new Date().getTime();

  const params = new URLSearchParams(location.search).get("t");
  const timeStamp = params.split("codes")[0];

  const elapsedMillies = now - +timeStamp;

  function millisToMinutes(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes;
  }

  const elapsedTime = millisToMinutes(elapsedMillies);

  const items = params.split("codes")[1].split("code");

  const buttonsToShow = items.reduce((acc, item) => {
    acc.push(products.find((p) => p.fields.productId === item));
    return acc;
  }, []);

  return (
    <div className='page-contents'>
      <h2>Downloads</h2>
      <p>Here you should see a button to download each of your items.</p>
      <p>Access to this page expires 24hrs after purchase time.</p>

      {items.length && (
        <>
          {elapsedTime < 1440 ? ( // 24hrs
            <>
              <table>
                {buttonsToShow?.map((item) => (
                  <tr className='downloads__item'>
                    <td>
                      <h5>{item?.fields.title}</h5>
                    </td>
                    <td>
                      <a className='button' download href={item?.fields.url}>
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          ) : (
            <h5>
              sorry, your download has expired. For assistance, please contact
              me at <a href='mailto:mail@adamwoolf.com'>mail@adamwoolf.com</a>
            </h5>
          )}
        </>
      )}
    </div>
  );
};

export default Download;
