import React from "react";
import { Row, Col } from "react-bootstrap";

const WebDev = () => {
  return (
    <div className='contents webdev'>
      <h4> Web Development</h4>
      <Row>
        <Col md={9}>
          <p>
            Alongside my career in the music industry, I have always been
            involved in some way with graphic design and web development using a
            variety of technology.
          </p>
          <p>
            In 2019 I started{" "}
            <a href='https://www.webspinner.eu'>WebSpinner.eu</a>, as a more
            official 'shop front' for developing custom websites for a range of
            clients. In the first half year I had built an international
            client-base of over 40 individuals and organisations. I was pleased
            to discover that the more I learned, the more I wanted to know, and
            that I loved working in an ever-changing, challenging environment
            combining design asthetics with technology.
          </p>
          <p>
            Shortly after this, the COVID19 pandemic hit, causing unbelievable
            destruction in the performing arts. With every concert cancelled,
            and unable to sustain a teaching career which relised heavily on
            travel, I decided to invest more time and energy into learning
            really cool skills which would allow me to build cutting edge web
            applications and to expand my horizons in the world of programming.
            Alongside Webspinner, I worked for a few months with New York-based
            design and development agency{" "}
            <a href='https://torii.studio' target='_blank'>
              {" "}
              Torii Studio
            </a>{" "}
            where I continued to hone my skills under the mentorship of ex
            Google employee Cris Chao.{" "}
          </p>

          <p>
            I now work for <a href='https://teamitg.com'>Team ITG</a>, a UK
            based company where I am a Lead Developer looking after websites for
            Jaguar Landrover and working on projects for other world-leading
            brands. currently{" "}
          </p>
          <p>
            If you'd like to collaborate, or have enquiries about websites,
            please <a href='mailto:mail@adamwoolf.com'>drop me a line.</a>
          </p>
          <a className='button' href='https://www.webspinner.eu'>
            Visit WebSpinner.eu and check out my portfolio.
          </a>
        </Col>
        <Col md={3} className='logos'>
          <img className='logo' src={"/imgs/logoReact.jpeg"} />
          <img className='logo' src={"/imgs/logoAngular.png"} />
          <img className='logo' src={"/imgs/logoJS.png"} />
          <img className='logo' src={"/imgs/logoHTML.png"} />
          <img className='logo' src={"/imgs/logoCSS.png"} />
        </Col>
      </Row>
    </div>
  );
};

export default WebDev;
