import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./MusicianHeroStyles.scss";
import Adam from "../../assets/trombone.jpg";
import StaggeredList from "../StaggeredList/StaggeredList";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

const MusicianHero = () => {
  const divRef = useRef();

  const { isVisible } = useIntersectionObserver(divRef);

  return (
    <div ref={divRef} className={`muso-hero ${!isVisible && "muso-hero--off"}`}>
      <div className='muso-hero__text'>
        <h2>Musician</h2>
        <div className='muso-hero__content'>
          <div className='video-container'>
            <iframe
              className='responsive-iframe'
              src='https://www.youtube.com/embed/3cnO7FdWicc?si=FS9y0OK9MKLlU6Te'
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            ></iframe>
          </div>
          <StaggeredList
            active={isVisible}
            items={[
              "World renowned classical performing artist",
              "Leading Educator",
              "Chamber Music Pioneer",
            ]}
          />
        </div>
      </div>
      {/* <img src={Adam} /> */}

      <Link to='/musician' className='cta more-cta muso-hero__cta'>
        more...
      </Link>
    </div>
  );
};

export default MusicianHero;
