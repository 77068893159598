import React, { Component } from "react";
import { getSinglePost } from "../contentful";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

import SinglePostDisplay from './SinglePostDisplay'

class SinglePost extends Component {
  state = {
 post: []
  };



  componentDidMount() {
    getSinglePost(this.props.match.params.id).then((data) =>
      this.setState({
      post: data
      })
    );
  }

  render() {
    return (
    <div>
      {this.state.post.map(post => <SinglePostDisplay post={post} />)}
    </div>
    );
  }
}

export default SinglePost;
