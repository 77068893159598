import React, { useEffect } from "react";
import "../styles/stylesheet.scss";
import Hero from "../components/Hero/Hero";
import WebDevHero from "../components/WebDevHero/WebDevHero";
import MusicianHero from "../components/MusicianHero/MusicianHero";
import StoreHero from "./StoreHero/StoreHero";
const Main = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Hero />
      <div>
        <StoreHero />
        <WebDevHero />
        <MusicianHero />
      </div>
    </div>
  );
};

export default Main;
