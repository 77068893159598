import React from "react";
import Title from "../Title";
import "./HeroStyles.scss";

const Hero = (props) => {
  return (
    <div className='hero'>
      <Title />
    </div>
  );
};

export default Hero;
