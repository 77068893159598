import React from "react";
import { forSale } from "../data/forSale";
const ForSalePage = () => {
  return (
    <div className='for-sale'>
      <h3>Second Hand Store</h3>
      {forSale.length === 0 && (
        <div>
          <h4>All sold out, for now!</h4>
          <p>thanks for looking.</p>
        </div>
      )}
      {forSale.length > 0 && (
        <>
          <p className='for-sale-intro'>
            Over during my time as a full time musician, I collected a large
            number of scores for performance projects, teaching, or just out of
            interest. Those listed below are in need of a new home. They're all
            still very usable, despite one or two pencil markings, and among
            this list are certainly some pieces which can't be found and
            downloaded online. Some have never been used.
          </p>
          <p>
            Although there are prices on each item, I'd be happy to consider
            offers for purchasing more than one item. Prices are excluding
            shipping.
          </p>

          <p>
            If you are interested, please{" "}
            <a href='mailto: mail@adamwoolf.com'>send me an email.</a>{" "}
          </p>

          <table>
            <tr>
              <th>Composer</th>
              <th>Title</th>
              <th>Instrumentation</th>
              <th>Edition</th>
              <th>Facsimile</th>
              <th>Condition</th>
              <th>Price</th>
            </tr>
            {forSale
              .filter((item) => !item.facsimile)
              .sort((a, b) => (a.composer > b.composer ? 1 : -1))
              .map((item, i) => (
                <tr style={i % 2 === 0 ? { backgroundColor: "#eee" } : {}}>
                  <td>{item.composer}</td>
                  <td>{item.title}</td>
                  <td>{item.instrumentation}</td>
                  <td>{item.edition}</td>
                  <td>{item.facsimile ? "Y" : "N"}</td>
                  <td>{item.condition}</td>
                  <td>€{item.price}</td>
                </tr>
              ))}
          </table>
          <hr style={{ marginTop: 50 }} />
          <h4>Facsimiles</h4>
          <table>
            <tr>
              <th>Composer</th>
              <th>Title</th>
              <th>Instrumentation</th>
              <th>Edition</th>
              <th>Facsimile</th>
              <th>Condition</th>
              <th>Price</th>
            </tr>
            {forSale
              .filter((item) => item.facsimile)
              .sort((a, b) => (a.composer > b.composer ? 1 : -1))
              .map((item, i) => (
                <tr style={i % 2 === 0 ? { backgroundColor: "#eee" } : {}}>
                  <td>{item.composer}</td>
                  <td>{item.title}</td>
                  <td>{item.instrumentation}</td>
                  <td>{item.edition}</td>
                  <td>{item.facsimile ? "Y" : "N"}</td>
                  <td>{item.condition}</td>
                  <td>€{item.price}</td>
                </tr>
              ))}
          </table>

          <p> More coming soon...</p>
        </>
      )}
    </div>
  );
};

export default ForSalePage;
