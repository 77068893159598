import React from "react";
import marked from "marked";
import {Link} from 'react-router-dom'
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, FacebookIcon, FacebookShareButton } from 'react-share'

const imageStyle = {
  width: "65px",
};

const BlogItem = ({ article }) => {

  const { title, heroImage, image, author, body, slug } = article.fields;
  const postBody = marked(body);
  const excerpt = postBody.split(" ").splice(0, 40).join(" ");

  return (
    <div>
      <h3>{title}</h3>
      <div className="meta">
        <p>
          {author && <em>{author.fields.name}</em>}</p>
        <p>  {author && (
            <a href="{author.fields.facebook}">
              <FaFacebook className="social" />
            </a>
          )}
          </p>
          <p>
          {author && (
            <a href="{author.fields.twitter}">
              <FaTwitter className="social" />
            </a>
          )}
        </p>
      </div>
      {heroImage && <img style={imageStyle} src={heroImage.fields.file.url} />}
      <section dangerouslySetInnerHTML={{ __html: excerpt + '...' }} />
      <div>
      <Link to={'/posts/' + slug} >Read more...</Link>
   

      </div>
      <div id="social">
<small>share on social media: </small>
      <TwitterShareButton url={window.location} children={<TwitterIcon  size={24}/>}/>
      <LinkedinShareButton url={window.location} children={<LinkedinIcon  size={24}/>}/>
      <FacebookShareButton url={window.location} children={<FacebookIcon  size={24}/>} />
      </div>
      <hr />
    </div>
  );
};

export default BlogItem;
