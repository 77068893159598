import React, { useEffect } from "react";

const Discography = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className='contents'>
        <h3>Discography</h3>
        <p>
          A large part of my career has included CD recording with various
          orchestras and ensembles. Here is a reverse-chronological list of the
          commercially available CDs on which I can be heard: And there is still
          more in the pipeline so why not bookmark this page so you can check
          for updates?…
        </p>

        <ul>
          <li>109 – Love is Come Again – Gardiner/SDG</li>
          <li>108 – Luther – The noble art of Music – In Alto/Utopia</li>
          <li>
            107 – Un Cornetto a Roma – Ensemble In Alto/Colson – Passacaille
          </li>
          <li>
            106 – ‘Carrousel’ – Holland Baroque & Eric Vloeimans / Channel
            Classics
          </li>
          <li>
            105 – The Food of Love – Solo CD with Charles Daniels / Lupophonic
          </li>
          <li>104 – Fux Requiem – Vox Luminis / Ricercar</li>
          <li>103 – “You Us We All” Shara Worden/BOX</li>
          <li>102 – Amaranthine – Let The Music Speak / Lupophonic</li>
          <li>101 – “Western Wind” – Andrew Parrot / Avie</li>
          <li>100 – Bertali “La Maddalena” Scherzi Musicale/Ricercar</li>
          <li>
            99 – Monteverdi Vespers 1610. DVD recording. Gardiner/Monteverdi
            Choir/English Baroque Soloists. – Outhere Music
          </li>
          <li>
            98 – Praetorius Easter Mass – Manfred Cordes/Weser Renaissance –
            Radio Bremen
          </li>
          <li>
            97 – Schütz Sacrae Symphonie I – Manfred Cordes/Weser Renaissance –
            CPO
          </li>
          <li>
            96 – Gabrieli in Venice 1615 – King’s Cambridge/His Majesty’s
            Sagbutts & Cornetts
          </li>
          <li>95 – Bach Motets – Vox Luminis / Ricercar</li>
          <li>94 – Bruckner 1 – Musica Saeculorum/Steinaecker/Fra Bernardo</li>
          <li>
            93 – ‘La Luchesina’ Guami – His Majesty’s Sagbutts & Cornetts/SFZ
          </li>
          <li>92 – “Ortus de Polonia” Les Traversees Baroques</li>
          <li>91 – Coronation Music for Charles II – Oltremontano/Accent</li>
          <li>90 – Something Blue – Mardi Brass LIR</li>
          <li>89 – ‘Virgo Prudenstissima’ – Weser Renaissance, Bremen. CPO</li>
          <li>88 – Bellini “Norma” DVD: Fabio Biodi/Teatro Regio, Parma</li>
          <li>
            87 – Zamponi “Ulisse All’Isola Di Circe. Ensemble Clematis RICERCAR
          </li>
          <li>86 – Missa Solemnis, Beethoven – Gardiner/ORR SDG718</li>
          <li>85 – Praetorius Ostermesse – Weser Renaissance, Bremen.</li>
          <li>84 – Gabrieli ‘Sacrae Symphoniae’ Oltremontano. Accent24282</li>
          <li>
            83 – Mozart’s Die Schuldigkeit Des Ersten Gebots. Obligato alto
            trombone solo. Classical Opera Company. Signum Classics
          </li>
          <li>82 – Monteverdi’s L’Orfeo – Parrott, Teverner Consort</li>
          <li>
            81 – Capriccio Stravagante – Skip Sempe – Muse of the Dance
            Praetorius, Brade
          </li>
          <li>
            80 – Sacred Symphonies, Gabrieli – Ex Cathedra/HMSC/Concerto
            Palatino – HYPERION
          </li>
          <li>
            79 – At His Majesty’s Pleasure – Harry/His Majestys Sagbutts &
            Cornetts/SFZ
          </li>
          <li>
            78 – Beethoven Symphony No.5 Live from Carnegie Hall – ORR/Gardiner
          </li>
          <li>
            77 – A New Venetian Coronation – The Gabrieli Consort/Signum
            Records.
          </li>
          <li>76 – Castello & Co – Caecilia-Concert, Challenge Classics</li>
          <li>75 – Gardiner/ORR/Monteverdi Choir: Brahms Requiem. SDG706</li>
          <li>
            74 – ‘HARK!’ – Mardi Brass / King’s Lynn Festival Chorus LIR024
          </li>
          <li>
            73 – His Majestys Sagbutts & Cornetts, Choir of St John’s Cambridge
            – Lassus Sacred Music – CHANDOS
          </li>
          <li>
            72 – Monteverdi Vespers – Orchestra of the Age of Enlightenment –
            Signum Records
          </li>
          <li>71 – ‘Songs Without Words’ solo CD SFZM0510</li>
          <li>70 – Gardiner / ORR Brahms Symphony No.4 SDG 705</li>
          <li>69 – Gardiner / EBS Bach Cantatas (BWV162 & 38) SDG168</li>
          <li>68 – Gardiner / EBS Bach Cantatas (BWV 2) SDG 165</li>
          <li>67 – Gardiner / EBS Bach Cantatas (BWV 96) SDG 159</li>
          <li>
            66 – Canzoni per Sonate – His Majestys Sagbutts & Cornetts –
            SFZM0209
          </li>
          <li>65 – Schmelzer & Co – Caecilia-Concert, Challenge Classics</li>
          <li>64 – Something Borrowed – Mardi Brass LIR</li>
          <li>63 – Gardiner / ORR: Brahms Symphony No.3 & Nänie SDG704</li>
          <li>
            62 – Lux Obscura: un projet électro-médiéval- Ballon Noir/Virgin
            Classics
          </li>
          <li>61 – Gardiner / EBS Bach Cantatas (BWV102) SDG 147</li>
          <li>60 – Gardiner / EBS Bach Cantatas (BWV25) SDG 124</li>
          <li>59 – Gardiner / EBS Bach Cantatas (BWV 64) SDG 127</li>
          <li>58 – Gardiner / EBS Bach Cantatas (BWV68) SDG 121</li>
          <li>57 – Gardiner / EBS Bach Cantatas (BWV28) SDG137</li>
          <li>
            56 – Ludwig Senfl: Missa Pascalis Motteten & Lieder QuintEssential /
            Sydney College Cambridge Choir
          </li>
          <li>CCLCD704 Obsidian Records</li>
          <li>55 – Brahms Symphony No.2… ORR/Gardiner SDG703</li>
          <li>54 – Buccaneer – His Majestys Sagbutts & Cornett. SFZM0108</li>
          <li>
            53 – Joan Pau Pujol – Musica para el Corpus. La Grande
            Chapelle/Lauda LAU007
          </li>
          <li>52 – Brahms Symphony No.1… ORR/Gardiner SDG702</li>
          <li>
            51 – South American Baroque – Volume 3. QuintEssential / Ex
            Cathedra. HYPERION
          </li>
          <li>50 – Mardi Brass ‘Something New’ LIR015</li>
          <li>49 – Lasso Maria Vespers – Weser Renaissance Bremen CPO</li>
          <li>48 – Weihnachtskantaten – Les Agremens RICERCAR</li>
          <li>
            47 – Music for The Twelve Days of Christmas and The Nativity
            HMSC/SFZ0307
          </li>
          <li>
            46 – La Pellegrina – Capricio Stravagante/Skip Sempe Paradiso PA0004
          </li>
          <li>45 – Don Juan – La Grande Chapelle</li>
          <li>44 – Lambert De Sayve, sacred music – Oltremontano KLARA</li>
          <li>
            43 – Guerrero – His Majestys Sagbutts and Cornetts, GLOSSA GCD922005
          </li>
          <li>42 – Giovanni Battista Grillo – HMSC; SFZ0107</li>
          <li>
            41 – Buxtehude and Co. Caecilia-Concert, Challenge Records / Antoine
            Marchant
          </li>
          <li>40 – VSPRS Platel/Cassol/Monteverdi/Oltremontano – CYP0602</li>
          <li>
            39 – Monteverdi Choir/English Baroque Soloists Bach Cantata
            Pilgrimage SDG121
          </li>
          <li>
            38 – Monteverdi Vespers – The Kings Consort – Hyperion (also SA)
            CDA67531/2
          </li>
          <li>
            37 – Treasury of a Saint – Caecilia-Concert CC72161 Antoine Marchand
            / Challenge Records int.
          </li>
          <li>36 – Gardiner / EBS Bach Cantatas SDG113</li>
          <li>35 – Weber Oberon – ORR Philips 000464602</li>
          <li>34 – Something Old – Mardi Brass, LIR010</li>
          <li>33 – King’s Consort Monteverdi Volume 4 CDA67519</li>
          <li>
            32 – Biber Missa regensburgensis – English Concert Harmonia Mundi
          </li>
          <li>31 – The Kings Consort Collection – HYPERION – KING7</li>
          <li>
            30 – Michael Haydn Requiem and Mass – Kings Consort HYPERION CDA
            67510
          </li>
          <li>29 – Biber Requiem – Gabrieli Consort DG 4747142</li>
          <li>
            28 – Monteverdi – the sacred music Vol.3 Kings Consort HYPERION
            SACDA67487
          </li>
          <li>27 – Les Troyens – DVD ORR Pro Arte.</li>
          <li>26 – Rupert Bawden’s “The Sailors Tale” NMC Records</li>
          <li>25 – Haydn Motet: Insanae . . . . – EBS Phillips 2894708192</li>
          <li>24 – Tallis Spem in Alium – The Sixteen COROSACD 16016</li>
          <li>
            23 – Monteverdi – The Sacred Music volume 1, The King’s Consort
            HYPERION CDA67428
          </li>
          <li>22 – ECSE/I Fagiolini – Andrea Gabrieli – Chandos CHAN0697</li>
          <li>
            21 – QuintEssential / Ex Cathedra South American Baroque – HYPERION
          </li>
          <li>20 – Cantium Winds – Cardinal Records CSCD102</li>
          <li>
            19 – Eliza is the Fayrest Queen – QE Chandos records. CHACONNE
            CHAN0686
          </li>
          <li>18 – Virtuoso in the making – Ricordo Linn CKD195</li>
          <li>17 – A Bach Album – HMSC HYPERION CDA67247</li>
          <li>
            16 – Sacred vocal music of Johann Schelle – Kings Consort HYPERION
          </li>
          <li>
            15 – Capriccio Stravagante vol II – HMSC The Purcell Quartet CHAN
            0670
          </li>
          <li>14 – Accendo The English Cornett and Sackbut Ensemble.</li>
          <li>
            13 – The Purcell Quartet/HMSC – Capriccio Stravagante vol1, track 5
            (CHANDOS) Chan0651
          </li>
          <li>
            12 – Bach Christmas Cantatas – Christmas. 64, 121 English Baroque
            Soloists Bach Cantata Pilgrimage Series
          </li>
          <li>11 – The London Trombone Sound – Cala</li>
          <li>10 – Andrea Gabrieli – Missa Pater peccavi – HMSC, Hyperion</li>
          <li>
            9 – Sacred Vocal music of Sebastian Knupfer – The King’s Consort
          </li>
          <li>8 – Lo Spozolizio – The Kings Consort</li>
          <li>7 – Once as I remember – Monteverdi Choir</li>
          <li>6 – Schütz Musikalische Exequien – The Sixteen, COLLINS</li>
          <li>5 – Guerrero – Battle Mass HMSC, Westminster Cath. HYPERION</li>
          <li>4 – Floating City – HMSC HYPERION</li>
          <li>
            3 – The Call of the Beloved – Victoria Sacred Vocal music, The
            Sixteen, COLLINS
          </li>
          <li>
            2 – Canzonas & Sonatas from 1597 – Giovanni Gabrieli. HMSC, HYPERION
          </li>
          <li>1 – In Venetia – QuintEssential, MERIDIAN</li>
        </ul>
      </div>
    </div>
  );
};

export default Discography;
