import React, { useRef, useState } from "react";
import "./StoreHeroStyles.scss";
import { Link } from "react-router-dom";
import StaggeredList from "../StaggeredList/StaggeredList";
import SS from "../../assets/SS.jpg";
import DS from "../../assets/ds.jpg";
import tfol from "../../assets/tfol.jpg";
import sww from "../../assets/sww.jpg";
import am from "../../assets/am.jpg";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

const StoreHero = () => {
  const divRef = useRef();
  const { isVisible } = useIntersectionObserver(divRef);

  return (
    <div
      ref={divRef}
      className={`store-hero ${!isVisible && "store-hero--off"}`}
    >
      <div className='store-hero__images'>
        <img className='store-hero__images--book' src={SS} />
        <img className='store-hero__images--book' src={DS} />
        <div className='store-hero__images--cd'>
          <img src={tfol} />
        </div>
        <div className='store-hero__images--cd'>
          <img src={sww} />
        </div>
        <div className='store-hero__images--cd'>
          <img src={am} />
        </div>
      </div>
      <div className='store-hero__text'>
        <h2>Online Store</h2>

        <StaggeredList
          active={isVisible}
          items={["Method Books", "Digital Downloads", "CDs"]}
        />
        <Link to='/store' className='cta more-cta store-hero__cta'>
          more...
        </Link>
      </div>
    </div>
  );
};

export default StoreHero;
