import React, { useEffect, useState } from "react";

const useIntersectionObserver = (elementRef) => {
  const [entry, setEntry] = useState(null);

  const updateEntry = (entry) => {
    setEntry(entry);
  };

  useEffect(() => {
    console.log(elementRef);
    const element = elementRef?.current;
    if (!element) return;

    const observer = new IntersectionObserver(updateEntry, { threshold: 0.8 });
    observer.observe(element);

    return () => observer.disconnect();
  }, [elementRef]);
  return { entry, isVisible: entry?.[0].isIntersecting };
};

export default useIntersectionObserver;
