export const forSale = [
  // {
  //   composer: "Ferro, Maro Antonio",
  //   title: "Sonaten 11 & 12 à 4",
  //   instrumentation: "SSTB",
  //   edition: "Musiche Varie, score and parts",
  //   facsimile: false,
  //   price: 5,
  //   condition: "used.  includes some pencil markings.",
  // },
  // {
  //   composer: "Ferro, Maro Antonio",
  //   title: "Sonaten 9 & 10 à 4",
  //   instrumentation: "",
  //   edition: "Musiche Varie, score and parts",
  //   facsimile: false,
  //   price: 5,
  //   condition: "good.",
  // },
  // {
  //   composer: "Cesare, Giovanni Martino",
  //   title: "Beata es, Virgo Maria",
  //   instrumentation: "tenor voice, 3 trombones, continuo",
  //   edition: "Musiche Varie, score and parts",
  //   facsimile: false,
  //   price: 5,
  //   condition: "good.",
  // },
  // {
  //   composer: "Lasso, Rudolf di",
  //   title: "Regina Coeli",
  //   instrumentation: "soprano, cornetto, 3 trombones, continuo",
  //   edition: "Musiche Varie, score and parts",
  //   facsimile: false,
  //   price: 5,
  //   condition: "good.",
  // },
  // {
  //   composer: "Various",
  //   title: "Music for the Duke of Lerma",
  //   instrumentation: "Instrumental from 4 - 6 voices",
  //   edition: "Amherst Early Music",
  //   facsimile: false,
  //   price: 25,
  //   condition: "good.",
  // },
  // {
  //   composer: "Freddi, Amadio",
  //   title: "Message Vespro et Competia 1616",
  //   instrumentation: "5 voices, cornetto, violin, continuo",
  //   edition: "Becker",
  //   facsimile: true,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Antegnati, Constanzo",
  //   title: "Psalms & Magnificat à 8",
  //   instrumentation: "8 voices",
  //   edition: "Becker",
  //   facsimile: true,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Gabrieli, Giovanni",
  //   title: "Salvator noster à 15 1615",
  //   instrumentation: "3 5-part choirs, 'high, normal, low",
  //   edition: "Charteris, King's Music",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "McKenzie, Jock",
  //   title: "The Boar's Head Wassail",
  //   instrumentation: "Brass dectet and percussion",
  //   edition: "Superbrass",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  //   modernBrass: true,
  // },
  // {
  //   composer: "Waterman, Steve",
  //   title: "Fugatango",
  //   instrumentation: "Brass dectet and percussion",
  //   edition: "Superbrass",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  //   modernBrass: true,
  // },
  // {
  //   composer: "Wood, Gareth",
  //   title: "Tientos y Danzas",
  //   instrumentation: "Brass dectet and percussion",
  //   edition: "Superbrass",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  //   modernBrass: true,
  // },
  // {
  //   composer: "Monteverdi",
  //   title: "Orfeo",
  //   instrumentation: "orchestra & voices",
  //   edition: "Bärenreiter",
  //   facsimile: true,
  //   price: 20,
  //   condition: "good.",
  // },
  // {
  //   composer: "Scheidt, Samuel",
  //   title: "Ludi Musici",
  //   instrumentation: "SSATB instruments",
  //   edition: "London Pro Musica: score and part books",
  //   facsimile: false,
  //   price: 30,
  //   condition: "good.",
  // },
  // {
  //   composer: "Kempis, Nicolao a",
  //   title: "Symphoniae, unius, duarium et trium violinorum. Volume 2",
  //   instrumentation: "2 violins & continuo",
  //   edition: "Musedita",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Kempis, Nicolao a",
  //   title: "Symphoniae, unius, duarium et trium violinorum. Volume 1",
  //   instrumentation: "solo violin & continuo",
  //   edition: "Musedita",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Lupo, Thomas",
  //   title: "3 pavans for 3 instruments",
  //   instrumentation: "C, T, B instrumental",
  //   edition: "London Pro Musica",
  //   facsimile: false,
  //   price: 4,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Sonata à 10",
  //   instrumentation: "SSATB + SSATB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Sonata Seconda à 8",
  //   instrumentation: "STTB + STTB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Quinta à 8",
  //   instrumentation: "SSTB + SSTB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Seconda à 8",
  //   instrumentation: "SSAT + SSAT",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon à 7",
  //   instrumentation: "SSSATTB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon à 10",
  //   instrumentation: "SSATB + SSATB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Sonata Prima à 8",
  //   instrumentation: "STTB + STTB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Quarta à 8",
  //   instrumentation: "SATB + SATB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Prima à 8",
  //   instrumentation: "SSSA + TTBB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Terza à 8",
  //   instrumentation: "SATB + SATB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Quarta à 6",
  //   instrumentation: "SSSBBB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Terza à 6",
  //   instrumentation: "SSATTB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Secunda à 6",
  //   instrumentation: "SSATTB",
  //   edition: "London Pro Musica. Score and parts.",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Priuli, Giovanni",
  //   title: "Canzon Prima à 6",
  //   instrumentation: "SSATTB",
  //   edition: "London Pro Musica. Score only.",
  //   facsimile: false,
  //   price: 4,
  //   condition: "good.",
  // },
  // {
  //   composer: "Ganassi, Giacomo",
  //   title: "8 sonatas for violin and trombone",
  //   instrumentation: "violin, trombone, continuo",
  //   edition: "Musedita.  Score only",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Bononcini, GM",
  //   title: "Varii fiori del giardino musicale Op3",
  //   instrumentation: "1 - 4 strings instruments & continuo",
  //   edition: "Musedita. score and parts",
  //   facsimile: false,
  //   price: 8,
  //   condition: "good.",
  // },
  // {
  //   composer: "Cazzati, M",
  //   title: "Il secondo libro delle sonate",
  //   instrumentation: "sonatas for 2, 3, 4 & 6 instruments & continuo",
  //   edition: "Musedita score and parts.",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Montalbano, Bartolomeo",
  //   title: "Sinfonie a uno, e dio violini e trombone",
  //   instrumentation: "sonatas for 2 violins and trombone with continuo",
  //   edition: "SPES",
  //   facsimile: true,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Deering, Richard",
  //   title: "2 Pavans for 5 Instruments",
  //   instrumentation: "SSTTB / SSATB",
  //   edition: "London Pro Musica",
  //   facsimile: false,
  //   price: 3,
  //   condition: "good.",
  // },
  // {
  //   composer: "Selma, Bartolomea de Selma",
  //   title: "Canzoni Fantasie et Correnti",
  //   instrumentation: "2, 3, 4 instruments with continuo",
  //   edition: "SPES",
  //   facsimile: true,
  //   price: 20,
  //   condition: "good.",
  // },
  // {
  //   composer: "Schmelzer, Heinrich",
  //   title: "Sonata XII a 7",
  //   instrumentation: "arranged for 10 part modern brass ensemble",
  //   edition: "Cherry Classics: score and parts",
  //   facsimile: false,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Kempis, Nicolaus a",
  //   title: "Symphoniae Opus 2",
  //   instrumentation: "for 1, 2, 3, 4 and 5 instruments and continuo",
  //   edition: "Musedita: score and parts",
  //   facsimile: false,
  //   price: 15,
  //   condition: "good.",
  // },
  // {
  //   composer: "Ortiz, Diego",
  //   title: "Trattado de Glosas",
  //   instrumentation: "Ornamentation method",
  //   edition: "Bärenreiter.  Hard cover",
  //   facsimile: false,
  //   price: 15,
  //   condition: "good.",
  // },
  // {
  //   composer: "Cima, Paolo",
  //   title: "Concerti Ecclesiastici",
  //   instrumentation:
  //     "vocal and instrumental pieces for 1 - 4 voices / instruments and continuo.",
  //   edition: "SPES",
  //   facsimile: true,
  //   price: 15,
  //   condition: "good.",
  // },
  // {
  //   composer: "Various",
  //   title: "Canzoni per Sonare - Raveri",
  //   instrumentation:
  //     "instrumental pieces for 4, 5 and 8 instruments and continuo.",
  //   edition: "SPES",
  //   facsimile: true,
  //   price: 15,
  //   condition: "good.",
  // },
  // {
  //   composer: "Riccio, Giovanni Battista",
  //   title: "Il Terzo Libro delle divine lodi musicali",
  //   instrumentation:
  //     "vocal and instrumental pieces for 1 - 4 voices/instruments and continuo.",
  //   edition: "SPES",
  //   facsimile: true,
  //   price: 10,
  //   condition: "good.",
  // },
  // {
  //   composer: "Marini, Biagio",
  //   title: "Sonate de Chiesa e da Camera",
  //   instrumentation:
  //     "instrumental pieces for 2, 3 and instruments and continuo.",
  //   edition: "SPES",
  //   facsimile: true,
  //   price: 10,
  //   condition: "good.",
  // },
];
