import React, { useState, useEffect } from "react";
import "./CookieBannerStyles.scss";
const CookieBanner = () => {
  const [open, setOpen] = useState(true);

  return (
    <div className={`cookie-banner ${!open && "cookie-banner--leaving"}`}>
      <button
        className='cookie-banner__cta cookie-banner__cta--accept'
        onClick={() => setOpen(false)}
      >
        Agree
      </button>
      <p>
        When making a purchase from this website you will be required to submit
        personal information such as your email address and name. This is to
        confirm that your personal details will only be used for the purposes of
        carrying out the transaction.
      </p>
    </div>
  );
};

export default CookieBanner;
