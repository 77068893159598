import React from "react";

const StoreItem = ({ item, index, itemCodes, removeItem, addItem }) => {
  return (
    <div className='store__item' key={index}>
      <div>
        {item.fields.isDownload && !item.fields.isMp3 && (
          <span className='store__item__download-badge' />
        )}
        {item.fields.isMp3 && <span className='store__item__mp3-badge' />}
        <img src={item.fields.image.fields.file.url} />
        <div className='store__item__text__buttons'>
          <p className='store__item__price'>
            €{item.fields.price} inc. shipping
          </p>

          <button
            onClick={() => addItem(item)}
            className={
              itemCodes.includes(item.fields.productId)
                ? "store__item__button--selected"
                : "store__item__button"
            }
          >
            {itemCodes.includes(item.fields.productId)
              ? "selected"
              : "add to cart"}
          </button>
          {itemCodes.includes(item.fields.productId) && (
            <button
              onClick={() => removeItem(item)}
              className='store__item__button--remove'
            >
              remove
            </button>
          )}
        </div>
        <div className='store__item__text'>
          <h6>{item.fields.title}</h6>

          <p>{item.fields.description}</p>
        </div>
      </div>
    </div>
  );
};

export default StoreItem;
