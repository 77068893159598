import React, { useRef } from "react";
import "./WebDevHeroStyles.scss";
import { Link } from "react-router-dom";
import StaggeredList from "../StaggeredList/StaggeredList";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

const WebDevHero = () => {
  const divRef = useRef();

  const { isVisible } = useIntersectionObserver(divRef);
  return (
    <div
      ref={divRef}
      className={`webdev-hero ${!isVisible && "webdev-hero--off"}`}
    >
      <h2>Web Development</h2>

      <StaggeredList
        active={isVisible}
        items={[
          "Professional development of over 40 websites and applications",
          "Lead Developer for world-famous automobile brands",
          "Mentorship and Professional Growth",
        ]}
      />
      <Link to='/webdev' className='cta more-cta'>
        more...
      </Link>
    </div>
  );
};

export default WebDevHero;
