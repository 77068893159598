import React, { useState, useEffect } from "react";
import { getStoreItems } from "../../contentful";
import "./StoreStyles.scss";
import Checkout from "./Checkout";
import StoreItem from "./StoreItem";

const Store = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showPP, setShowPP] = useState(false);
  const [basket, setBasket] = useState([]);
  const [itemCodes, setItemCodes] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    getStoreItems().then((data) => setProducts(data));
  }, []);

  const addItem = (item) => {
    setBasket([...basket, item]);
    setItemCodes([...itemCodes, item.fields.productId]);
  };

  const removeItem = (item) => {
    setBasket(
      basket.filter((i) => i.fields.productId !== item.fields.productId)
    );
    setItemCodes(itemCodes.filter((code) => code !== item.fields.productId));
  };

  return (
    <div>
      <div>
        <div className='store__header'>
          <h4>Online Store</h4>
          <p>
            Here you can purchase my books and CDs. All prices include shipping.
          </p>
          <p>
            Physical books and CDs will be shipped from Spain upon receipt of
            automated payment. Downloads will be available immediately via a
            link in your confirmation email and upon redirection after payment.
            Links to downloads expire after 3 days.
          </p>
        </div>
        <Checkout
          setBasket={setBasket}
          setShowCheckout={setShowCheckout}
          itemCodes={itemCodes}
          basket={basket}
          showCheckout={showCheckout}
        />
        <h3 className='store__heading'>Downloads</h3>
        <div className='store__items'>
          {products
            .filter((p) => p.fields.isDownload)
            .map((item, index) => (
              <StoreItem
                item={item}
                index={index}
                addItem={addItem}
                removeItem={removeItem}
                itemCodes={itemCodes}
              />
            ))}
        </div>
        <hr />
        <h3 className='store__heading'>All Products</h3>
        <div className='store__items'>
          {products.map((item, index) => (
            <StoreItem
              item={item}
              index={index}
              addItem={addItem}
              removeItem={removeItem}
              itemCodes={itemCodes}
            />
          ))}
        </div>
      </div>
      {showCheckout && <div className='checkout__backdrop' />}
    </div>
  );
};

export default Store;
