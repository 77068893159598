import React from "react";

const Title = () => {
  return (
    <div className='hero-title'>
      <h1>Adam Woolf</h1>
      <p>Web Developer / Musician</p>
    </div>
  );
};

export default Title;
