const client = require('contentful').createClient({
    space: 'a9ly9rah62ns',
    accessToken: 'hO5sRsMG9k4UpzhQhX44CTG2-mE39O2ZolIvEzgBMJY'
  })
  
  const getBlogPosts = () => client.getEntries({content_type: 'blogPost'}).then(response => response.items)
  const getStoreItems = () => client.getEntries({content_type: 'storeItem'}).then(response => response.items)
  const getScores = () => client.getEntries({content_type: 'score'}).then(response => response.items)
  const getTracks = () => client.getEntries({content_type: 'mp3'}).then(response => response.items)
  
  const getSinglePost = slug =>
    client
      .getEntries({
        'fields.slug': slug,
        content_type: 'blogPost'
      })
      .then(response => response.items)
  
  export {getScores, getTracks, getBlogPosts, getSinglePost, getStoreItems }