import React, { Component } from "react";
import Main from "./components/Main";
import Store from "./components/Store/Store";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import WebDev from "./components/WebDev";
import Posts from "./components/Posts";
import Footer from "./components/Footer";
import Musician from "./components/Musician";
import Discography from "./components/Discography";
import Media from "./components/Media";
import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import SinglePost from "./components/SinglePost";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ForSale from "./components/ForSale";
import Download from "./components/Download/Download";
import CookieBanner from "./components/CookieBanner/CookieBanner";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <Router>
        <div style={{ height: "100%" }}>
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer
            show={this.state.sideDrawerOpen}
            click={this.drawerToggleClickHandler}
          />
          {backdrop}
          <main style={{ marginTop: "56px" }}></main>{" "}
          <Switch>
            <Route exact path='/' component={Main} />
            <Route path='/store' component={Store} />
            <Route path='/download' component={Download} />
            <Route path='/photos' component={Gallery} />
            <Route path='/contact' component={Contact} />
            <Route path='/webdev' component={WebDev} />
            <Route path='/posts/:id' component={SinglePost} />

            <Route path='/posts' component={Posts} />
            <Route path='/musician' component={Musician} />
            <Route path='/discography' component={Discography} />
            <Route path='/media' component={Media} />
            <Route path='/for_sale' component={ForSale} />
          </Switch>
          <CookieBanner />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
