import React, { useRef, useEffect, useState } from "react";
import "./StaggeredListStyles.scss";

const StaggeredList = ({ items, active }) => {
  const listRef = useRef();

  if (!active) return null;

  return (
    <ul ref={listRef} className={`list list--animate`}>
      {items?.map((item, i) => (
        <li style={{ animationDelay: `${i / 2}s` }}>{item}</li>
      ))}
    </ul>
  );
};

export default StaggeredList;
